import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RecognizedAnswer } from './recognizedAnswer';
import { State } from './processingTaskState';
import { exception } from 'console';

@Injectable()
export class HttpService {
    main_url = 'https://speech.usedesk.services/backend/api/AudioProcessing/';
    path = 'GetTaskResult?id=0f9b4870-263a-40b3-a6d3-ed9a89cd762b';
    text_method = 'CreateTask';
    text_with_emotions_method = 'CreateTaskSttAndVoiceFeatures';
    token: string;
    
    constructor(private http: HttpClient) { 
        this.getToken();
    }

    async delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private getToken() {
        this.token = localStorage["token"];
    }

    getLink(filename: string) {
        let name = filename.split('__')[1];
        return this.main_url + 'Download?filename=' + name + '&apikey=' + this.token;
    }

    async getStatesData() {
        this.getToken();
        return await this.http.get(this.main_url + 'GetProcessingStates?apikey=' + this.token).toPromise();
    }

    async getStates() {
        let states;
        try {
            await this.getStatesData().then((data) => {
                states = data;
            });
        } catch (error) {
        }
        return states;
    }

    async createTask(link: string, channelsCount: string, emotions: boolean, language: string, stt: string) {
        let data = {
            "file_url": link
        }

        let method = emotions ? this.text_with_emotions_method : this.text_method;

        return await this.http.post(this.main_url + method + '?apikey=demo_71ec2b79-1093-499d-b596-ebd21c32158f&channelsCount=' + channelsCount + stt + language, data).toPromise();
    }

    async sendNotification(taskLink: string) {
        return this.http.get('https://alarmerbot.ru/?key=1d4df0-bf99ed-56c2a8&message=Подозрительный звонок ' + encodeURIComponent(taskLink)).toPromise();
    }

    async sendError(text: string) {
        return this.http.get('https://alarmerbot.ru/?key=1d4df0-bf99ed-56c2a8&message=Ошибка на сервере ' + text).toPromise();
    }

    async getTaskResult(id: string) {
        return this.http.get(this.main_url + 'GetTaskResult?id=' + id).toPromise();
    }

    async getResultFromBuffer(formData: FormData, channelsCount: string, language: string) {
        return await this.http.post(this.main_url + 'GetTextAndVoiceFeatures?apikey=demo_71ec2b79-1093-499d-b596-ebd21c32158f&channelsCount=' + channelsCount + language, formData).toPromise();
    }

    async recognizeFromBlob(blob, emotions, language) {
        let resultModel = new RecognizedAnswer();
        try {
            let answerModel: RecognizedAnswer;
            let formData = new FormData();
            formData.append('file', blob);

            let answerData;

            if (language == "en") {
                language = "&language=en";
            } else {
                language = "";
            }

            await this.getResultFromBuffer(formData, "1", language).then((data: string) => {
                answerData = JSON.parse(data);
            });
            if (answerData[0]["Status"] != "Error") {
                answerModel = answerData[0];
            }

            resultModel.AudioDialogDescriptor = answerModel.AudioDialogDescriptor;
            resultModel.FragmentFeatures = answerModel.FragmentFeatures;
            resultModel.Status = answerModel.Status;

            if (emotions) {
                resultModel.createFeatures("Client");
            }
        } catch (error) {
            await this.sendError(error);
        }

        return resultModel;
    }

    async recognize(url, channelsCount, emotions, language, stt, taskId) {
        let resultModel = new RecognizedAnswer();
        try {
            let idData = {
                "id": "id"
            }

            if (taskId != null) {
                idData["id"] = taskId;
            }

            let answerModel: RecognizedAnswer;
            answerModel = null;
            let createTaskAnswer;
            let error = false;

            if (language == "en") {
                language = "&language=en";
            } else {
                language = "";
            }

            if (stt == "ya") {
                stt = "&useYandex=False";
            } else {
                stt = "";
            }

            if (taskId == null) {
                await this.createTask(url, channelsCount, emotions, language, stt).then((data) => {
                    createTaskAnswer = data;
                });

                idData = JSON.parse(createTaskAnswer);
            }

            while (answerModel == null) {
                let idDataY;
                await this.getTaskResult(idData["id"]).then((data: string) => {
                    idDataY = JSON.parse(data);
                });
                if (idDataY["Status"] != "Error") {
                    answerModel = idDataY;
                    break;
                }

                if (idDataY["Error"] == "Task_is_not_found") {
                    error = true;
                    break;
                }

                console.log("waiting answer");
                await this.delay(3000);
            };

            if (error) {
                resultModel.Status = "Error";
                return resultModel;
            }

            resultModel.Status = answerModel.Status;
            resultModel.AudioDialogDescriptor = answerModel.AudioDialogDescriptor;
            resultModel.FragmentFeatures = answerModel.FragmentFeatures;
            resultModel.TaskId = idData["id"];

            if (emotions) {
                if (channelsCount == "1") {
                    resultModel.createFeatures("Client");
                } else {
                    resultModel.createFeatures("Client");
                    resultModel.createFeatures("Operator");
                }
            }
        } catch (error) {
            await this.sendError(error);
        }

        return resultModel;
    }
}