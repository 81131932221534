
<header class="header">
    <menu></menu>
</header>

<div class="container">

    <div class="contaiber-batch">
        <section class="info-input ">
            <div  class='info-form contacts'>
                <p>Почта: <a href="mailto:alessander.smirnov@gmail.com">alessander.smirnov@gmail.com</a></p>
                <p>LinkedIn: <a href="https://www.linkedin.com/in/alessander-smirnov/" target="_blank">LinkedIn</a></p>
                <p>Briqbot: <a href="http://briqbot.com" target="_blank">briqbot.com</a></p>
            </div>
        </section>
    </div>
</div>