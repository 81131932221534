<div class="hamburger-menu">
      <input id="menu__toggle" type="checkbox" />
      
        <label class="menu__btn" for="menu__toggle">
          <span></span>
        </label>

    <ul class="menu__box">
        <li><a class="menu__item" routerLink="/calls">Обработать новую запись</a></li>
        <li><a class="menu__item" routerLink="/batch">Звонки</a></li>
        <li><a class="menu__item" routerLink="/contacts">Контакты</a></li>
    </ul>
</div>