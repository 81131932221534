import { Component, Input } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';
import { Label } from 'ng2-charts';

@Component({
    selector: 'emotions-chart',
    templateUrl: './emotionschart.component.html',
    styleUrls: ['../calls.component.scss']
})

export class EmotionsChartComponent {
    @Input() moodData;
    constructor() { }

    lineChartData: ChartDataSets[];
    barChartLabels: Label[];

    getMoodData(owner) {
        let sorted = this.getSortLabels();
        let result = [];
        let min = this.getMinValue();
        if (owner == "client") {
            for (let idx in sorted) {
                if (this.moodData[sorted[idx]]["c"] != null) {
                    result.push(this.moodData[sorted[idx]]["c"]);
                } else {
                    result.push(min);
                }
            }
        } else {
            for (let idx in sorted) {
                if (this.moodData[sorted[idx]]["o"] != null) {
                    result.push(this.moodData[sorted[idx]]["o"]);
                } else {
                    result.push(min);
                }
            }
        }
        return result;
    }

    getMinValue() {
        let min = 1;
        for (let item in this.moodData) {
            if (this.moodData[item]["o"] != null && this.moodData[item]["o"] < min) {
                min = this.moodData[item]["o"];
            }
            if (this.moodData[item]["c"] != null && this.moodData[item]["c"] < min) {
                min = this.moodData[item]["c"];
            }
        }
        this.lineChartOptions.scales.yAxes[0].ticks.min = Math.floor(min * 100) / 100;
        return Math.floor(min * 100) / 100;
    }

    getSortLabels() {
        let sorted = [];
        for (let key in this.moodData) {
            sorted.push(Number(key));
        }
        sorted.sort((a, b) => {
            return a - b;
        });
        return sorted;
    }

    getSortLabelsToMinutes() {
        let sorted = this.getSortLabels();
        let result = [];
        for (let idx in sorted) {
            let minute = Math.trunc(sorted[idx] / 60);
            let second = sorted[idx] % 60;
            let secondStr = second.toString();
            if (second < 10) {
                secondStr = '0' + second.toString();
            }
            result.push(minute + ':' + secondStr);
        }
        return result;
    }

    lineChartOptions: ChartOptions = {
        responsive: true,
        scales: {
            xAxes: [{
            }],
            yAxes: [{
                ticks: {
                    min: 0
                }
            }]
        }
    };

    lineChartColors: Color[] = [
        {
            borderColor: 'blue',
            backgroundColor: 'rgba(0,0,255,0.88)'
        },
        {
            borderColor: 'green',
            backgroundColor: 'rgba(0,255,0,0.88)'
        },
    ];

    lineChartType = 'bar';

    ngOnInit(): void {
        this.lineChartData = [
            {
                fill: false,
                label: 'Настроение клиента',
                data: this.getMoodData("client")
            },
            {
                fill: false,
                label: 'Настроение оператора',
                data: this.getMoodData("operator")
            }
        ];
        this.barChartLabels = this.getSortLabelsToMinutes();
    }
}