import { Component } from '@angular/core';
import { HttpService } from '../http.service';
import { State } from '../processingTaskState';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MoodCellRenderer } from '../cellRenderers/moodCellRenderer'
import { DateCellRenderer } from '../cellRenderers/dateCellRenderer'
import { NameCellRenderer } from '../cellRenderers/nameCellRenderer'

@Component({
    selector: 'batch',
    styleUrls: ['../calls.component.scss'],
    providers: [HttpService],
    templateUrl: './batch.component.html'
})

export class BatchComponent {
    constructor(private httpService: HttpService, private activateRoute: ActivatedRoute,
        private location: Location, private router: Router) {
        let param = activateRoute.snapshot.params['apikey'];
        if (param == null) {
            if (localStorage.hasOwnProperty("token")) {
                if (localStorage["token"].length <= 9) {
                    this.apikey = "demo_71ec2b79-1093-499d-b596-ebd21c32158f";
                } else {
                    this.apikey = localStorage["token"];
                }
            } else {
                this.apikey = "demo_71ec2b79-1093-499d-b596-ebd21c32158f";
            }
        } else {
            this.apikey = param;
            localStorage.setItem('token', this.apikey);
        }
    }
    states: State[];
    apikey: string;
    private gridApi;

    columnDefs = [
        {
            field: 'fileName', headerName: 'Имя файла', minWidth: 200,
            sortable: true, filter: true, cellRenderer: NameCellRenderer
        },
        {
            field: 'createDate', headerName: 'Дата создания', minWidth: 200,
            maxWidth: 200, sortable: true, filter: true, cellRenderer: DateCellRenderer
        },
        {
            field: 'recognizedText', headerName: 'Распознанный текст', sortable: true, filter: true
        },
        {
            field: 'avgMoodLevel', headerName: 'Уровень позитива', minWidth: 200,
            maxWidth: 200, sortable: true, filter: true, cellRenderer: MoodCellRenderer
        },
        {
            field: 'taskId', headerName: 'Id', minWidth: 120,
            maxWidth: 120, sortable: true, filter: true, hide: true
        },
        { field: 'error', headerName: 'Ошибки', sortable: true, filter: true, hide: true }
    ];
    rowData;

    async getStates() {
        this.states = await this.httpService.getStates();
        this.rowData = this.states;
    }

    onSelectionChanged() {
        let selectedRows = this.gridApi.getSelectedRows();
        this.openFile(selectedRows[0].fileName, selectedRows[0].taskId);
    }

    openFile(filename, taskId) {
        let link = this.httpService.getLink(filename);
        this.goToTaskResult(taskId, link);
    }

    goToTaskResult(taskId: string, audiolink: string) {
        if (taskId != null) {
            this.router.navigateByUrl('/calls/' + taskId + '?audiolink=' + encodeURIComponent(audiolink));
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    ngOnInit(): void {
        localStorage.setItem('token', this.apikey);
        this.getStates();
    }
}