<header class="header">
    <menu></menu>
</header>

<div class="container">
    <div class="contaiber-batch">'
        <div class="batch-block">
            <div>
                <ag-grid-angular style="width: 100%; height: 500px;" class="ag-theme-alpine" [rowData]="rowData"
                    [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" (selectionChanged)="onSelectionChanged()" rowSelection="single">
                </ag-grid-angular>
            </div>
        </div>

    </div>
</div>
