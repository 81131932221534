<div class="title">
  <button (click)="initiateRecording()" class='button' *ngIf="!recording">
    <div class="text__button">Начать запись<span class="img__mic__on"></span></div>
  </button>
  <button (click)="stopRecording()" class='button' *ngIf="recording">
    <div class="text__button">Остановить запись <span class="img__mic__off"></span></div>
    
  </button>
  <div *ngIf="recording" class="progress-ring"></div>
  <audio controls="" *ngIf="urlblob" class="audio">
    <source [src]="sanitizeUrl(urlblob)" type="audio/wav">
  </audio>
</div>