<!--Header-->
<section class='header'>
    <div class="container">
        <div class="panel">
            <div class="title">Welcome, Friend! Tell me...<br> {{trainer_phrases[trainer_phrases_idx]}}</div>
            <div class="title">(at least 5 seconds)</div>
            <div class="title">
                <button (click)="initiateRecording()" class='button' *ngIf="!recording">
                    <div class="text__button">Start Recording</div>
                    <div class="img__mic__on"></div>
                </button>
                <button (click)="stopRecording()" class='button' *ngIf="recording">
                    <div class="text__button">Stop Recording</div>
                    <div class="img__mic__off"></div>
                </button>
                <div *ngIf="recording" class="progress-ring"></div>
                <audio controls="" *ngIf="urlblob" class="audio">
                    <source [src]="sanitizeUrl(urlblob)" type="audio/wav">
                </audio>
            </div>
            <div class="title">Or add a link to your record</div>
            <div class='input__link'>
                <input class="form-control" [(ngModel)]="url" placeholder="Record link" />
            </div>
            <div class="title">I will test your language skills</div>
            <button *ngIf="recordready || url !=''" class="btn" (click)="recognize(url, channelsCount)">
                <div class='button'>
                    <div class="text__button">Evaluate</div>
                </div>
            </button>
            <div *ngIf="running" class="progress-ring"></div>
        </div>
    </div>
</section>
<!--Result-->
<div *ngIf="done">
    <section class='result'>
        <div class="container">
            <div class="panel">
                <div *ngIf="done">
                    <div class="title">Not bad, let's see your results</div>
                </div>
                <div *ngIf="done">
                    <div class="title">Check out your score:</div>

                    <div class='show'>{{showConfidence(finalScore)}}</div>
                </div>
            </div>
            <div class='info'>
                <!--block messager-->
                <div class="detail" id="iphone_id">
                    <div class="iphone">
                        <div class="dinamic"></div>
                        <div class="iphone-display">
                            <div class="msg">
                                <div class="item11">
                                    <div class="name-msg1"><b>Trainer</b></div>
                                    <div class="text-msg1">{{trainer_phrases[trainer_phrases_idx]}}</div>
                                </div>
                                <br>
                                <div class="item2">
                                    <div class="item22">
                                        <div class="name-msg1"><b>Student</b></div>
                                        <div class="text-msg1" [innerHTML]="this.highlightPhrase(learnerText)"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Detail-->
                <div class="detail">
                    <div class="title">Learner speach details</div>
                    <div class="difference">
                        <p id='text'>Used complex constructions:</p>
                        <p id='number'>{{usedComplexConstructions}}</p>
                    </div>
                    <div class="difference">
                        <p id='text'>Word count:</p>
                        <p id='number'>{{commonWordsCount}}</p>
                    </div>
                    <div class="difference">
                        <p id='text'>Different word count:</p>
                        <p id='number'>{{differentWordsCount}}</p>
                    </div>
                    <div class="difference">
                        <p id='text'>Pauses:</p>
                        <p id='number'>{{pausesCount}}</p>
                    </div>
                    <div class="difference">
                        <p id='text'>Accent:</p>
                        <p id='number'>{{showConfidence(learnerAccent)}}</p>
                    </div>
                </div>
                <!--detail voice 1-->
                <div class="detail">
                    <div class="title">Learner voice details</div>
                    <div class="detail-items">
                        <div class="detail-voice">
                            <p>Sex:</p>
                            <p id='number'>{{this.recognizedAnswer.Client.Sex.Feature == "Мужской" ? "Man" : "Woman"}}
                            </p>
                        </div>
                        <div class="detail-voice">
                            <p>Age:</p>
                            <p id='number'>{{this.recognizedAnswer.Client.Age.Feature == "Взрослый" ? "Adult" : "Old"}}
                            </p>
                        </div>
                        <div class="detail-voice">
                            <p>Tempo:</p>
                            <p id='number'>{{this.recognizedAnswer.Client.Temp.Feature == "Быстрый темп" ? "Fast" :
                                "Normal"}}</p>
                        </div>
                        <div class="detail-voice">
                            <p>Mood:</p>
                            <p id='number'>{{this.recognizedAnswer.Client.Mood.Feature == "Спокоен" ? "Positive" :
                                "Normal or Sad"}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<section *ngIf="done" class='intro'>
    <div class="container">
        <div class="panel">
            <div class="container">
                <button (click)="update_theme()" class='button'>
                    <div class="text__button">New test</div>
                </button>
            </div>
        </div>
    </div>
</section>