import { Component } from '@angular/core';
import { HttpService } from './http.service';
import { RecognizedAnswer } from './recognizedAnswer';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Track } from 'ngx-audio-player';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AudioDialogDescriptor } from './recognizedAnswer';

@Component({
  selector: 'calls-app',
  providers: [HttpService],
  styleUrls: ['./calls.component.scss'],
  templateUrl: './calls.component.html'
})

export class CallsComponent {
  constructor(private sanitizer: DomSanitizer, private httpService: HttpService,
    private activateRoute: ActivatedRoute, private location: Location) {
    this.taskId = activateRoute.snapshot.params['id'];
    this.activateRoute.queryParams.subscribe(params => {
      if (params["audiolink"] != null) {
        this.url = decodeURIComponent(params['audiolink']);
      }
    });
  }

  url: string = "";
  channelsCount: string = "2";
  ht: SafeHtml;
  correct: string;
  notcorrect: string;
  notFoundCorrectPhrases: string[];
  recognizedAnswer: RecognizedAnswer = new RecognizedAnswer();
  running: boolean = false;
  done: boolean = false;
  correctNotFoundExists: boolean = false;
  emotions: boolean = true;
  twoChannels: boolean = false;
  mainblob;
  recordready: boolean = false;
  operatorAccent: number = 0;
  pausesCount: number = 0;
  taskId: string;
  backupDescriptor: AudioDialogDescriptor;
  moodData;

  // audio player
  msaapDisplayTitle = true;
  msaapDisplayPlayList = false;
  msaapPageSizeOptions = [2, 4, 6];
  msaapDisplayVolumeControls = false;
  msaapDisablePositionSlider = false;
  msaapDisplayRepeatControls = false;
  msaapPlaylist: Track[];

  async sendNotification() {
    await this.httpService.sendNotification(window.location.href);
  }

  playAudio() {
    this.msaapPlaylist = [
      {
        title: '',
        link: this.url
      }
    ];
  }

  set_time(time) {
    let audioBlock = document.querySelector("audio");
    if (time < 0) {
      audioBlock.currentTime = 0;
    }

    if (time > 0 && time < audioBlock.duration) {
      audioBlock.currentTime = time;
    }
  }

  goToTaskResult(taskId: string, audiolink: string) {
    if (taskId != null) {
      this.location.replaceState(
        '/calls/' + taskId + '?audiolink=' + encodeURIComponent(audiolink)
      );
    }
  }

  checkEmotions() {
    this.taskId = null;
    this.location.replaceState(
      '/calls/'
    );
  }

  async recognize(url) {
    this.done = false;
    this.running = true;
    //url = "https://www.dropbox.com/s/51ence1t7yynasm/example.mp3?dl=1";
    this.twoChannels = this.channelsCount == "2";
    if (this.url == null || this.url == "") {
      this.recognizedAnswer = await this.httpService.recognizeFromBlob(this.mainblob, this.emotions, "ru");
    } else {
      this.recognizedAnswer = await this.httpService.recognize(url, this.channelsCount, this.emotions, "ru", "", this.taskId);
    }

    console.log(this.recognizedAnswer);
    if (this.recognizedAnswer.Status != "Error") {
      this.goToTaskResult(this.recognizedAnswer.TaskId, this.url);
      this.calc_text_params();
      this.backupDescriptor = new AudioDialogDescriptor;
      this.backupDescriptor.Phrases = [];
      this.recognizedAnswer.AudioDialogDescriptor.Phrases.forEach(element => {
        this.backupDescriptor.Phrases.push(Object.assign({}, element));
      });
      if (this.emotions) {
        this.moodData = this.recognizedAnswer.MoodValues;
      }
      this.done = true;
    }

    this.running = false;
    this.recordready = false;

    if (this.url != null && this.url != "") {
      this.playAudio();

      await setTimeout(async () => {
        this.highlightPauses();
        let audioBlock = document.querySelector("audio");
        let lock = 0;

        audioBlock.ontimeupdate = () => {
          if (lock != 0) {
            return;
          }
          lock = 1;
          this.scrollToBlockByAudioPosition();
          setTimeout(() => {
              lock = 0;
          }, 2000);
        }
      }, 5000);
    }
  }

  scrollToBlockByAudioPosition() {
    let position = document.querySelector("audio").currentTime;
    if (this.recognizedAnswer != null) {
      let lengthPhrases = this.recognizedAnswer.AudioDialogDescriptor.Phrases.length;
      let blockIdx = 0;
      for (let j = 0; j < lengthPhrases; j++) {
        let item = this.recognizedAnswer.AudioDialogDescriptor.Phrases[j];
        if (item.EndingSecond > position) {
          blockIdx = j;
          break;
        }
      }
      let parentBlock = document.querySelector(".messege_block");
      let targetBlock = parentBlock.children[blockIdx];
      let scrollBlock = document.querySelector(".messege_block")
      let offsetParent = (scrollBlock as HTMLElement).offsetTop;
      let offsetChild = (targetBlock as HTMLElement).offsetTop;
      scrollBlock.scrollTop = offsetChild - offsetParent;

      (targetBlock.children[0] as HTMLElement).style.border = "3px rgb(224, 68, 68, 0.5) solid";
      setTimeout(() => {
        ((targetBlock.children[0] as HTMLElement).style.border = "none")
      }, 5000);
    }
  }

  calc_text_params() {
    this.operatorAccent = 0;
    let phrasesCount = 0;
    this.pausesCount = 0;
    let owner = 'Operator';
    if (!this.twoChannels) {
      owner = 'Client';
    }

    let lengthPhrases = this.recognizedAnswer.AudioDialogDescriptor.Phrases.length;
    for (let j = 0; j < lengthPhrases; j++) {
      let item = this.recognizedAnswer.AudioDialogDescriptor.Phrases[j];
      if (item.AuthorId == owner) {
        this.operatorAccent += Number(item.Confidence);
        phrasesCount++;
        if (j < lengthPhrases - 1) {
          if (this.recognizedAnswer.AudioDialogDescriptor.Phrases[j + 1].StartingSecond
            - this.recognizedAnswer.AudioDialogDescriptor.Phrases[j].EndingSecond > 1) {
            this.pausesCount++;
          }
        }
      }
    }

    this.operatorAccent /= phrasesCount;
  }

  find_phrases() {
    let dstCorrectArr = this.correct != null ? this.correct.split(",") : [];
    let dstNotCorrectArr = this.notcorrect != null ? this.notcorrect.split(",") : [];
    this.notFoundCorrectPhrases = [];
    this.recognizedAnswer.AudioDialogDescriptor.Phrases = [];
    this.backupDescriptor.Phrases.forEach(element => {
      this.recognizedAnswer.AudioDialogDescriptor.Phrases.push(Object.assign({}, element));
    });

    if (document.querySelector(".wraper__find") != null) {
      document.querySelector(".wraper__find").remove();
    }
    let wraperDiv = document.createElement("div");
    wraperDiv.classList.add("wraper__find");
    let correctColor = "green";
    let wrongColor = "red";
    let positionSetted = false;
    this.recognizedAnswer.AudioDialogDescriptor.Phrases.forEach(element => {
      let startSecond = element.StartingSecond;
      let endingSecond = element.EndingSecond;
      let arrayText = element.Text.toLowerCase().trim().split(' ');
      let length = arrayText.length

      dstCorrectArr.forEach(im => {
        let phrase = im.toLowerCase().trim().trimLeft();

        let index = arrayText.indexOf(phrase);
        if (index != -1) {
          this.positionFindPhrase(startSecond, endingSecond, wraperDiv, index, length, correctColor);
          if (!positionSetted)
          {
            this.set_time(startSecond);
            positionSetted = true;
          }
        }

        let pattern = "(^|\\s)" + phrase + "(?=\\s|$)";
        let re = new RegExp(pattern, "g");
        element.Text = element.Text.toLowerCase().replace(re, '<span style="background-color: #41c241c5; color:white"><b> ' + phrase + '</b></span>');
      });

      dstNotCorrectArr.forEach(im => {
        let phrase = im.toLowerCase().trim().trimLeft();

        let index = arrayText.indexOf(phrase);
        if (index != -1) {
          this.positionFindPhrase(startSecond, endingSecond, wraperDiv, index, length, wrongColor);
          if (!positionSetted)
          {
            this.set_time(startSecond);
            positionSetted = true;
          }
        }

        let pattern = "(^|\\s)" + phrase + "(?=\\s|$)";
        let re = new RegExp(pattern, "g");
        element.Text = element.Text.toLowerCase().replace(re, '<span style="background-color: #d14747c5; color:white"><b> ' + phrase + '</b></span>');
      });
    });

    dstCorrectArr.forEach(im => {
      let foundCorrectPhrase = false;
      let phrase = im.toLowerCase().trim();
      this.recognizedAnswer.AudioDialogDescriptor.Phrases.forEach(element => {
        if (element.Text.toLowerCase().includes(phrase)) {
          foundCorrectPhrase = true;
        }
      });
      if (!foundCorrectPhrase) {
        this.notFoundCorrectPhrases.push(phrase);
        this.correctNotFoundExists = true;
      }
    });
  }

  highlightPhrase(text) {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  showConfidence(conf) {
    return parseFloat(conf).toFixed(2)
  }

  highlightPauses() {
    let lengthPhrases = this.recognizedAnswer.AudioDialogDescriptor.Phrases.length;
    for (let j = 0; j < lengthPhrases; j++) {
      let item = this.recognizedAnswer.AudioDialogDescriptor.Phrases[j];
      this.addMuteToSlider(item.StartingSecond, item.EndingSecond,
        item.AuthorId);
    }
  }

  addMuteToSlider(start, end, owner) {
    let allTime = document.querySelector("audio").duration
    let width = (((end - start) / allTime) * 100);
    let position = ((start / allTime) * 100);
    let slide = document.querySelector(".mat-slider-ticks-container");
    (document.querySelector(".mat-slider-ticks-container") as HTMLElement)
      .style.height = "15px";
    let newDiv = document.createElement("div");
    slide.prepend(newDiv);
    newDiv.style.position = "absolute";
    newDiv.style.left = position + "%";
    newDiv.style.width = width + "%";
    newDiv.style.height = "15px";
    owner == "Client"
      ? newDiv.style.backgroundColor = "blue"
      : newDiv.style.backgroundColor = "green";
  };

  positionFindPhrase(start, end, wraper, index, length, color) {
    let slide = document.querySelector(".mat-slider-wrapper");
    let allTime = document.querySelector("audio").duration
    let newDiv = document.createElement("div");
    let position
    if (index == 0) {
      position = ((start / allTime) * 100)
    } else {
      position = (start + ((end - start) * (index / (length))) / allTime) * 100
    }
    if (position > 100) {
      position = (end / allTime) * 100
    }
    newDiv.classList.add("specified__word")
    slide.prepend(wraper);
    wraper.prepend(newDiv)
    wraper.style.width = "100%"
    newDiv.style.position = "absolute";
    newDiv.style.left = position + "%";
    newDiv.style.top = "25px"
    newDiv.style.width = "20px";
    newDiv.style.height = "20px";
    newDiv.style.backgroundColor = color;
    newDiv.style.zIndex = "1";
    newDiv.style.borderRadius = "50%";
  }

  ngOnInit(): void {
    if (this.url != "" && this.url != null)
      this.recognize(this.url);
  }
}
