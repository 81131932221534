import { Component } from '@angular/core';
import { HttpService } from '../http.service';
import { RecognizedAnswer } from '../recognizedAnswer';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as RecordRTC from 'recordrtc';

@Component({
  selector: 'lang-app',
  providers: [HttpService],
  styleUrls: ['./lang.component.scss'],
  templateUrl: './lang.component.html'
})

export class LangComponent {
  constructor(private sanitizer: DomSanitizer, private httpService: HttpService) { }
  url: string = "";
  channelsCount: string;
  ht: SafeHtml;
  correct: string;
  notcorrect: string;
  notFoundCorrectPhrases: string[];
  recognizedAnswer: RecognizedAnswer = new RecognizedAnswer();
  done: boolean = false;
  running: boolean = false;
  correctNotFoundExists: boolean = false;
  emotions: boolean = false;
  twoChannels: boolean = false;
  learnerText: string;
  learnerAccent: number = 0;
  commonWordsCount: number = 0;
  differentWordsCount: number = 0;
  usedComplexConstructions: number = 0;
  pausesCount: number = 0;
  finalScore: number = 0;
  record;
  recording: boolean = false;
  urlblob;
  mainblob;
  recordready: boolean = false;
  trainer_phrases: string[] = ["Why do you want to learn English?", "Do you like to travel?", "How do you have fun?", "What do you think about VR (virtual reality) technology?", "Which phone (or tablet, or PC) are you using now? Is it good?", "Which mobile apps do you use a lot?"];
  trainer_phrases_idx: number = Math.floor(Math.random() * (this.trainer_phrases.length));

  async recognize(url, channelsCount) {
    this.running = true;
    channelsCount = "1";
    this.done = false;
    this.emotions = true;

    //url = "https://www.dropbox.com/s/oxjh5c8yhk62zeq/4.wav?dl=1";

    this.twoChannels = channelsCount == "2";
    if (this.url == null || this.url == "") {
      this.recognizedAnswer = await this.httpService.recognizeFromBlob(this.mainblob, true, "en");
    } else {
      this.recognizedAnswer = await this.httpService.recognize(url, channelsCount, true, "en", "", null);
    }

    this.calc_text_params();
    this.find_phrases();
    this.calc_final_score();

    this.done = true;
    this.running = false;
    this.recordready = false;
  }

  calc_text_params() {
    this.learnerAccent = 0;
    let phrasesCount = 0;
    this.commonWordsCount = 0;
    this.differentWordsCount = 0;
    this.pausesCount = 0;

    let lengthPhrases = this.recognizedAnswer.AudioDialogDescriptor.Phrases.length;
    let words = [];
    for (let j = 0; j < lengthPhrases; j++) {
      let item = this.recognizedAnswer.AudioDialogDescriptor.Phrases[j];
      if (item.AuthorId == "Client") {
        this.learnerAccent += Number(item.Confidence);
        phrasesCount++;
        let newArr = item.Text.split(' ');
        for (let i of newArr) {
          words.push(i);
        }
        if (j < lengthPhrases - 1) {
          if (this.recognizedAnswer.AudioDialogDescriptor.Phrases[j + 1].StartingSecond
            - this.recognizedAnswer.AudioDialogDescriptor.Phrases[j].EndingSecond > 1) {
            this.pausesCount++;
          }
        }
      }
    }

    this.learnerText = words.join(" ");

    this.learnerAccent /= phrasesCount;
    this.commonWordsCount = words.length;
    this.differentWordsCount = new Set(words).size;
  }

  find_phrases() {
    let dstCorrectArr = ["used to", "proficiency", "of great importance", "value", "i d like to", "i d love to", "i find it", "looking forward to", "opportunity", "possibility", "i am going to", "i am planning to", "i would like to", "it seems", "sightseeing", "i am well traveled", "i ve been to", "i have not been to", "i have seen", "i would like to", "i am good at", "it feels", "i used to", "i am used to", "i d be happy to", "i have been", "i find it", "whenever i have time", "well", "so", "like", "again", "uhm", "absolutely", "accessible", "accord", "accelerate", "account", "accumulation", "across", "adjacent", "advocate", "afford", "against", "agenda", "ahead", "aim", "although", "allege", "allocation", "along", "ambiguity", "ambiguous", "amend", "analogy", "animated", "anonymous", "another", "anticipated", "apart", "apology", "apologize", "appeal", "appalling", "appointment", "appreciate", "gawk"];
    let dstNotCorrectArr = this.notcorrect != null ? this.notcorrect.split(",") : [];
    this.notFoundCorrectPhrases = [];
    this.usedComplexConstructions = 0;

    this.recognizedAnswer.AudioDialogDescriptor.Phrases.forEach(element => {
      dstCorrectArr.forEach(im => {
        let phrase = im.toLowerCase().trim().trimLeft();
        let re = new RegExp(phrase, "g");
        element.Text = element.Text.toLowerCase().replace(re, '<span style=color:green><b>' + phrase + '</b></span>');
      });

      dstNotCorrectArr.forEach(im => {
        let phrase = im.toLowerCase().trim().trimLeft();
        let re = new RegExp(phrase, "g");
        element.Text = element.Text.toLowerCase().replace(re, '<span style=color:red><b>' + phrase + '</b></span>');
      });
    });

    dstCorrectArr.forEach(im => {
      let phrase = im.toLowerCase().trim().trimLeft();
      let re = new RegExp(phrase, "g");
      this.learnerText = this.learnerText.toLowerCase().replace(re, '<span style=color:green><b>' + phrase + '</b></span>');
      if (this.learnerText.toLowerCase().includes(phrase)) {
        this.usedComplexConstructions++;
      }
    });

    dstCorrectArr.forEach(im => {
      let foundCorrectPhrase = false;
      let phrase = im.toLowerCase().trim();
      this.recognizedAnswer.AudioDialogDescriptor.Phrases.forEach(element => {
        if (element.Text.toLowerCase().includes(phrase)) {
          foundCorrectPhrase = true;
        }
      });
      if (!foundCorrectPhrase) {
        this.notFoundCorrectPhrases.push(phrase);
        this.correctNotFoundExists = true;

      }
    });
  }

  calc_final_score() {
    this.finalScore = 0;
    let pauseCoeff = this.pausesCount / 100;
    if (pauseCoeff > 0.1) {
      pauseCoeff = 0.1
    }

    let usedComplexConstructionsCoeff = this.usedComplexConstructions / 20;
    if (usedComplexConstructionsCoeff > 0.15) {
      usedComplexConstructionsCoeff = 0.15
    }

    this.finalScore = this.differentWordsCount / this.commonWordsCount * 0.2 + this.learnerAccent * 0.8;
    + usedComplexConstructionsCoeff - pauseCoeff;

    if (this.finalScore > 1.0) {
      this.finalScore = 1.0;
    }

    this.finalScore *= 100;
  }

  highlightPhrase(text) {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  showConfidence(conf) {
    return parseFloat(conf).toFixed(2)
  }

  sanitizeUrl(aurl: string) {
    let preprocessed = this.sanitizer.bypassSecurityTrustUrl(aurl);
    return preprocessed;
  }

  initiateRecording() {
    this.urlblob = null;
    this.recordready = false;
    this.done = false;
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  successCallback(stream) {
    let options = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1,
      sampleRate: 44000,
    };
    //Start Actuall Recording
    let StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }

  errorCallback(error) {
    console.log('Can not play audio in your browser');
  }

  stopRecording() {
    this.recording = false;
    this.recordready = true;
    this.record.stop(this.processRecording.bind(this));
  }

  /**
  * processRecording Do what ever you want with blob
  * @param  {any} blob Blog
  */
  processRecording(blob) {
    this.mainblob = blob;
    this.urlblob = URL.createObjectURL(blob);
  }

  update_theme() {
    this.trainer_phrases_idx = Math.floor(Math.random() * (this.trainer_phrases.length));
    this.urlblob = null;
    this.recordready = false;
    this.done = false;
  }
}
