<section class='header'>
    <div class="container">
        <div class="panel">
            <div class="logo"></div>
            <div class="title__main"><span class="span">Briq</span> Bot</div>
            <div class="main__text">
                Artificial Intelligence systems
            </div>
            <div class="main__text">
                Voice processing and analysis
            </div>
            <div class="buttons">
                <a class="linkTo" routerLink="/calls">
                    <button class='button'>
                        <div class="text__button">Phone Calls</div>
                    </button>
                </a>
                <a class="linkTo" routerLink="/langbot">
                    <button class='button'>
                        <div class="text__button">English trainer</div>
                    </button>
                </a>
            </div>
        </div>
    </div>
</section>