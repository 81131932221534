import { NgModule, Component } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HomeComponent } from './home.component/home.component';
import { LangComponent } from './lang.component/lang.component';
import { CallsComponent } from './calls.component';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecorderComponent } from './recorder.component'
import { EmotionsChartComponent } from './emotionschart.component/emotionschart.component'
import { MenuBurgerComponent } from './menuBurger.component/menuBurger.component'
import { ContactsComponent } from './contacts.component/contacts.component'
import { BatchComponent } from './batch.component/batch.component'
import { AgGridModule } from 'ag-grid-angular';

const appRoutes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'langbot', component: LangComponent },
    { path: 'calls', component: CallsComponent },
    { path: 'contacts', component: ContactsComponent },
    { path: 'batch', component: BatchComponent },
    { path: 'batch/:apikey', component: BatchComponent },
    { path: 'calls/:id', component: CallsComponent }
];

@NgModule({
    imports: [BrowserModule, AgGridModule.withComponents([]), FormsModule, HttpClientModule, RouterModule.forRoot(appRoutes), NgxAudioPlayerModule, BrowserAnimationsModule, ChartsModule],
    declarations: [AppComponent, HomeComponent, LangComponent, CallsComponent, RecorderComponent, EmotionsChartComponent, MenuBurgerComponent, ContactsComponent, BatchComponent],
    bootstrap: [AppComponent]
})
export class AppModule {

}