import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'recorder',
    styleUrls: ['./calls.component.scss'],
    templateUrl: './recorder.component.html'
})

export class RecorderComponent {
    constructor(private sanitizer: DomSanitizer) {
    }
    urlblob;
    record;
    recording: boolean;

    @Input() mainblob: Blob;
    @Output() mainblobChange = new EventEmitter<Blob>();

    @Input() channelsCount: string;
    @Output() channelsCountChange = new EventEmitter<string>();

    @Input() recordready: boolean;
    @Output() recordreadyChange = new EventEmitter<boolean>();

    @Input() done: boolean;
    @Output() doneChange = new EventEmitter<boolean>();

    initiateRecording() {
        this.urlblob = null;
        this.channelsCount = "1";
        this.channelsCountChange.emit(this.channelsCount);
        this.recordready = false;
        this.done = false;
        this.doneChange.emit(this.done);
        this.recording = true;
        let mediaConstraints = {
            video: false,
            audio: true
        };
        navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
    }

    successCallback(stream) {
        let options = {
            mimeType: "audio/wav",
            numberOfAudioChannels: 1,
            sampleRate: 44000,
        };
        //Start Actuall Recording
        let StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
        this.record = new StereoAudioRecorder(stream, options);
        this.record.record();
    }

    errorCallback(error) {
        console.log('Can not play audio in your browser');
    }

    stopRecording() {
        this.recording = false;
        this.recordready = true;
        this.recordreadyChange.emit(this.recordready);
        this.record.stop(this.processRecording.bind(this));
    }

    /**
    * processRecording Do what ever you want with blob
    * @param  {Blob} blob Blog
    */
    processRecording(blob) {
        this.mainblob = blob;
        this.urlblob = URL.createObjectURL(blob);
        this.mainblobChange.emit(this.mainblob);
    }

    sanitizeUrl(aurl: string) {
        let preprocessed = this.sanitizer.bypassSecurityTrustUrl(aurl);
        return preprocessed;
    }
}