export class NameCellRenderer {
    constructor() { }
    val;
    // init method gets the details of the cell to be renderer
    init(params) {
        this.val = document.createElement('span');
        this.val.innerHTML = params.value.slice(0);
    }

    getGui() {
        return this.val;
    }
}