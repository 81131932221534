<header class="header">
    <menu></menu>
</header>

<div class="container">


    <section *ngIf="!done" class="info-input">

        <div class='info-form'>

            <div class="form">

                <div class="form_title">Запишите речь (минимум 5 секунд)</div>

                <recorder [(mainblob)]="mainblob" [(channelsCount)]="channelsCount" [(recordready)]="recordready"
                    [(done)]="done">
                </recorder>

                <div class="form_title">Или добавьте ссылку на файл с записью</div>

                <div class='form_input_link'>
                    <input (keydown.enter)="recognize(url)" class="form-control" [(ngModel)]="url"
                        placeholder="Ссылка" />
                </div>

                <div class="form_radio_btn">
                    <input type="radio" name="channelsCount" [(ngModel)]="channelsCount" value="1" class="radio">
                    <span class="text_label">1 канал в файле</span>
                </div>

                <div class="form_radio_btn">
                    <input type="radio" name="channelsCount" [(ngModel)]="channelsCount" value="2" class="radio">
                    <span class="text_label">2 канала в файле</span>
                </div>

                <div class="form_checkbox">
                    <input [(ngModel)]="emotions" type="checkbox" [checked]="emotions" (change)="checkEmotions"
                        class='checkbox'>
                    <span class="text_label"> Определить детали голоса</span>
                </div>


                <div class='button'>
                    <button *ngIf="recordready || url !=''" class="btn" (click)="recognize(url)">
                        <p>Получить аналитику по звонку</p>
                    </button>

                </div>

                <div *ngIf="running" class="progress-ring">
                    <div class="outer">
                        <div class="inner">
                        </div>
                    </div>
                    <div class="outer" style="-webkit-animation-delay: 0.167s">
                        <div class="inner">
                        </div>
                    </div>
                    <div class="outer" style="-webkit-animation-delay: 0.334s">
                        <div class="inner">
                        </div>
                    </div>
                    <div class="outer" style="-webkit-animation-delay: 0.501s">
                        <div class="inner">
                        </div>
                    </div>
                    <div class="outer" style="-webkit-animation-delay: 0.668s">
                        <div class="inner">
                        </div>
                    </div>
                    <div class="outer" style="-webkit-animation-delay: 0.835s">
                        <div class="inner">
                        </div>
                    </div>
                </div>



            </div>

        </div>
    </section>
    <!--section info-form-->


    <section *ngIf="done" class="result">

        <div class="megaaudioplayer" *ngIf="done && twoChannels && url != ''">
            <ngx-audio-player [playlist]="msaapPlaylist" [displayTitle]="msaapDisplayTitle" [autoPlay]="true"
                muted="muted" [displayPlaylist]="msaapDisplayPlayList" [pageSizeOptions]="msaapPageSizeOptions"
                [displayVolumeControls]="msaapDisplayVolumeControls"
                [disablePositionSlider]="msaapDisablePositionSlider" [msaapDisplayRepeatControls]="msaapDisplayRepeatControls" [expanded]="true"></ngx-audio-player>
            <div class="wrapper__chart">
                <emotions-chart *ngIf="emotions" [(moodData)]="moodData"></emotions-chart>
            </div>
        </div>

        <div class="finde-phrases">

            <div class="info-form">

                <div class="form_title">Поиск в диалоге<br>(указать фразы через запятую)</div>

                <div class="form_input_link">
                    <textarea class="form_textarea" rows="2" id="correct" placeholder="фразы"
                        [(ngModel)]="correct"></textarea>
                </div>

                <!-- <div class="form_input_link">
                    <textarea class="form_textarea" rows="2" id="notcorrect" placeholder="Неверные фразы"
                        [(ngModel)]="notcorrect"></textarea>
                </div> -->

                <div class='button'>
                    <button class="btn" (click)="find_phrases()">Найти фразы</button>
                </div>

                <div *ngIf="correctNotFoundExists" class="detail-voice_item">

                    <div class="block_title">Не найденные фразы</div>

                    <div class="detail_found_phrase">

                        <p *ngFor="let item of notFoundCorrectPhrases">{{item}}</p>

                    </div>

                </div>

                <div class="detail-voice_item" *ngIf="emotions && twoChannels">

                    <div class="block_title">Детали голоса оператора</div>

                    <div class="block_detail">
                        <div class="detail-voice">
                            <p>Пол: <span
                                    class="span-detail-voice">{{this.recognizedAnswer.Operator.Sex.Feature}}</span></p>
                        </div>
                        <div class="detail-voice">
                            <p>Возраст: <span
                                    class="span-detail-voice">{{this.recognizedAnswer.Operator.Age.Feature}}</span></p>
                        </div>
                        <div class="detail-voice">
                            <p>Темп: <span
                                    class="span-detail-voice">{{this.recognizedAnswer.Operator.Temp.Feature}}</span></p>
                        </div>
                        <div class="detail-voice">
                            <p>Настроение: <span
                                    class="span-detail-voice">{{this.recognizedAnswer.Operator.Mood.Feature}}</span></p>
                        </div>
                        <div class="detail-voice">
                            <p>Количество пауз: <span class="span-detail-voice">{{pausesCount}}</span></p>
                        </div>
                        <div class="detail-voice">
                            <p>Разборчивость речи: <span
                                    class="span-detail-voice">{{showConfidence(operatorAccent)}}</span></p>
                        </div>
                    </div>

                </div>

                <div class="detail-voice_item" *ngIf="emotions">

                    <div class="block_title">Детали голоса клиента</div>

                    <div class="block_detail">
                        <div class="detail-voice">
                            <p>Пол: <span class="span-detail-voice">{{this.recognizedAnswer.Client.Sex.Feature}}</span>
                            </p>
                        </div>
                        <div class="detail-voice">
                            <p>Возраст: <span
                                    class="span-detail-voice">{{this.recognizedAnswer.Client.Age.Feature}}</span></p>
                        </div>
                        <div class="detail-voice">
                            <p>Темп: <span
                                    class="span-detail-voice">{{this.recognizedAnswer.Client.Temp.Feature}}</span></p>
                        </div>
                        <div class="detail-voice">
                            <p>Настроение: <span
                                    class="span-detail-voice">{{this.recognizedAnswer.Client.Mood.Feature}}</span></p>
                        </div>
                    </div>

                </div>

            </div>

            <div class="result_messege">
                <div class="messege_block">
                    <!--iphone-display-->

                    <div class="messege_item" *ngFor="let item of recognizedAnswer.AudioDialogDescriptor.Phrases"
                        (click)="set_time(item.StartingSecond - 1)">

                        <div *ngIf="item.AuthorId == 'Operator'" class="msg-operator">
                            <div class="msg-text" [innerHTML]="this.highlightPhrase(item.Text)"></div>
                        </div>
                        <div *ngIf="item.AuthorId == 'Client'" class="msg-client">
                            <div class="msg-text" [innerHTML]="this.highlightPhrase(item.Text)"></div>
                        </div>

                    </div>

                </div>

            </div>

        </div>


    </section>
    <!--section result-->

    <section *ngIf="done" class="detail-voice">






        <div class='button'>
            <button *ngIf="done" class="btn" (click)="sendNotification()">Оповестить о звонке</button>
        </div>

    </section>
    <!--section detail-voice-->

</div>